export const UserInfoList = [
  {
    sectionTitle: "User Info",
    itemList: [
      {
        label: "Name",
        name: "DisplayName",
        type: "text",
      },
      {
        label: "isPayer",
        name: "Payer",
        type: "check-box",
      },
      {
        label: "User ID",
        name: "UID",
        type: "text",
      },
      {
        label: "CreatedAt",
        name: "CreatedAt",
        type: "time",
      },
    ],
  },
  {
    sectionTitle: "User Currency",
    itemList: [
      {
        label: "Free Coins",
        name: "FreeCoins",
        type: "number"
      },
      {
        label: "Free Potions",
        name: "FreePotions",
        type: "number"
      },
      {
        label: "Paid Coins",
        name: "PaidCoins",
        type: "number"
      },
      {
        label: "Paid Potions",
        name: "PaidPotions",
        type: "number"
      }
    ]
  },
  {
    sectionTitle: "User Games",
    itemList: [
      {
        label: "GamesPlayed",
        name: "UserStats.GamesPlayed",
        type: "number",
      },
      {
        label: "Win Streak",
        name: "UserStats.WinStreak",
        type: "number",
      },
      {
        label: "Private Games Played",
        name: "UserStats.PGamesPlayed",
        type: "number",
      },
      {
        label: "2P 500 Games Played (Only Lose/Win)",
        name: "UserStats.Public2PGames500",
        type: "number",
      },
    ],
  },
  {
    sectionTitle: "Season",
    itemList: [
      {
        label: "Token Count",
        name: "UserStats.SeasonTokenCount",
        type: "number",
      },
      {
        label: "Pass Enabled",
        name: "UserStats.PassEnabled",
        type: "check-box",
      },
    ],
  },
  {
    sectionTitle: "Daily Deal",
    itemList: [
      {
        label: "Current Deal",
        name: "DailyDealData.CurrentDailyDeal",
        type: "text",
      },
      {
        label: "Slot1 Old Deal",
        name: "DailyDealData.Slot1DailyDeal",
        type: "number",
      },
      {
        label: "Slot2 Old Deal",
        name: "DailyDealData.Slot2DailyDeal",
        type: "number",
      },
      {
        label: "Slot3 Old Deal",
        name: "DailyDealData.Slot3DailyDeal",
        type: "number",
      },
    ],
  },
  {
    sectionTitle: "E Mail",
    itemList: [
      {
        label: "Email",
        name: "Email",
        type: "text",
      },
      {
        label: "EmailRequestData",
        name: "EmailRequestData",
        type: "text",
      },
    ],
  },
  {
    sectionTitle: "User XP",
    itemList: [
      {
        label: "XP Value",
        name: "XP",
        type: "number",
      },
      {
        label: "XP Expiry",
        name: "XPMultiplierExpiry",
        type: "time",
      },
      {
        label: "XP Mul",
        name: "XPMultiplier",
        type: "number",
      },
    ],
  },
  {
    sectionTitle: "Collections",
    itemList: [
      {
        label: "Platinum Coins",
        name: "PlatinumCoins",
        type: "number",
      },
      {
        label: "Rainbow Ink",
        name: "RainbowInk",
        type: "number",
      },
      {
        label: "Gold Spanner",
        name: "GoldSpanner",
        type: "number",
      },
    ],
  },
  {
    sectionTitle: "Add Collection Asset",
    itemList: [
      {
        label: "Collection Type",
        name: "collectionType",
        type: "select",
        values: ["dice", "frame", "token"],
        valueLabels: ["dice", "frame", "token"],
      },
      {
        label: "ID",
        name: "collectionID",
        type: "number",
      },
      {
        label: "Count",
        name: "count",
        type: "number",
      },
      {
        label: "Add Dice",
        name: "CollectionAdd",
        type: "buttonCollection",
      },
    ],
  },
  {
    sectionTitle: "Cannon Event / Treasure",
    itemList: [
      {
        label: "Primary Ammo",
        name: "Event.PrimaryItem",
        type: "number",
      },
      {
        label: "Secondary Ammo",
        name: "Event.SecondaryItem",
        type: "number",
      },
      {
        label: "Progress",
        name: "Event.Progress",
        type: "number",
      },
      {
        label: "Rewards Collected",
        name: "Event.RewardCollected",
        type: "number",
      },
      {
        label: "Card Reward",
        name: "Event.CardIDGiven",
        type: "text",
      },
    ],
  },
  {
    sectionTitle: "Special Parchisi / Streak",
    itemList: [
      {
        label: "Games Won",
        name: "SpecialParchisStats.GamesWon",
        type: "number",
      },
      {
        label: "Rentries",
        name: "SpecialParchisStats.ReEntries",
        type: "number",
      },
      {
        label: "FeesPaid",
        name: "SpecialParchisStats.FeesPaid",
        type: "check-box",
      },
    ],
  },
  {
    sectionTitle: "Chest Data",
    itemList: [
      {
        label: "Chest Slot 1",
        name: "ChestsData[0]",
        type: "select",
        values: [0, 1, 2, 3, 4],
        valueLabels: [0, 1, 2, 3, 4],
      },
      {
        label: "Chest Slot 2",
        name: "ChestsData[1]",
        type: "select",
        values: [0, 1, 2, 3, 4],
        valueLabels: [0, 1, 2, 3, 4],
      },
      {
        label: "Chest Slot 3",
        name: "ChestsData[2]",
        type: "select",
        values: [0, 1, 2, 3, 4],
        valueLabels: [0, 1, 2, 3, 4],
      },
    ],
  },
  {
    sectionTitle: "Opening Chest",
    itemList: [
      {
        label: "Chest ID",
        name: "OpeningChest.i",
        type: "number",
      },
      {
        label: "ChestOpen Time",
        name: "OpeningChest.t",
        type: "time",
      },
    ],
  },
  {
    sectionTitle: "Pick Card",
    itemList: [
      {
        label: "PickCardData",
        name: "PickCardsData",
        type: "text",
      },
      {
        label: "PickCard Time",
        name: "LastMysteryBoxTime",
        type: "time",
      },
    ],
  },
  {
    sectionTitle: "Fortune Data",
    itemList: [
      {
        label: "Next Free Time",
        name: "FortuneData.NextFreeFortuneTime",
        type: "time",
      },
      {
        label: "Fortune Type",
        name: "FortuneData.Type",
        type: "select",
        values: [0, 1],
        valueLabels: [0, 1],
      },
    ],
  },
  {
    sectionTitle: "Ads",
    itemList: [
      {
        label: "Ad Number",
        name: "AdNumber",
        type: "number",
      },
      {
        label: "Ad Reset Time",
        name: "AdReset",
        type: "time",
      },
    ],
  },
  {
    sectionTitle: "Card Collection",
    itemList: [
      {
        label: "Album No",
        name: "RandAlbumNo",
        type: "number",
      },
      {
        label: "Get a Random Card",
        name: "CardColection",
        type: "button",
      },
    ],
  },
  {
    sectionTitle: "Card Collection particular card",
    itemList: [
      {
        label: "Album No",
        name: "AlbumNo",
        type: "number",
      },
      {
        label: "Collection No",
        name: "CollectionNo",
        type: "number",
      },
      {
        label: "Card No",
        name: "CardNo",
        type: "number",
      },
      {
        label: "Get Card",
        name: "CardColection",
        type: "buttonNew",
      },
    ],
  },
  {
    sectionTitle: "Leaderboard",
    itemList: [
      {
        label: "Add bots to leaderboard",
        name: "Leaderboard Bots",
        type: "LeaderboardBotsBtn",
      },
    ],
  },
  {
    sectionTitle: "Set user leaderboard rank",
    itemList: [
      {
        label: "Desired rank",
        name: "LeaderboardRank",
        type: "number",
      },
      {
        label: "Update Rank",
        name: "LeagueRank",
        type: "UpdateLeaderboardRankBtn",
      },
    ],
  },
  {
    sectionTitle: "League",
    itemList: [
      {
        label: "League Tier",
        name: "CurrentLeague",
        type: "number",
      },
      {
        label: "Add bots to user current league",
        name: "League Bots",
        type: "LeagueBotsBtn",
      },
    ],
  },
  {
    sectionTitle: "Set user league rank",
    itemList: [
      {
        label: "Desired rank",
        name: "LeagueRank",
        type: "number",
      },
      {
        label: "Update Rank",
        name: "LeagueRank",
        type: "UpdateLeagueRankBtn",
      },
    ],
  },
  {
    sectionTitle: "Reset user league",
    itemList: [
      {
        label: "reset league",
        name: "resetLeague",
        type: "buttonLeagueReset",
      },
    ],
  },
  {
    sectionTitle: "Set user previous league and rank",
    itemList: [
      {
        label: "previous league",
        name: "LastLeague",
        type: "number",
      },
      {
        label: "previous rank",
        name: "LastLeagueRank",
        type: "number",
      },
    ],
  },
  {
    sectionTitle: "Set user cosmetic data",
    itemList: [
      {
        label: "Frame",
        name: "ActiveFrame",
        type: "number",
      },
    ],
  },
  {
    sectionTitle: "Reset Card Collection",
    itemList: [
      {
        label: "Album No",
        name: "ResetAlbumNo",
        type: "number",
      },
      {
        label: "Reset",
        name: "CardColectionReset",
        type: "buttonCardCollectionReset",
      },
    ],
  },
  {
    sectionTitle: "VIP",
    itemList: [
      {
        label: "Maximum VIP Type",
        name: "LudoVipData.MaxType",
        type: "select",
        values: [0, 1, 2],
        valueLabels: [0, 1, 2],
      },
      {
        label: "Current VIP Type",
        name: "LudoVipData.Type",
        type: "select",
        values: [0, 1, 2],
        valueLabels: [0, 1, 2],
      },
      {
        label: "Expiry",
        name: "LudoVipData.Expiry",
        type: "time"
      },
      {
        label: "DailyReward collected time",
        name: "LudoVipData.RewardCollectTime",
        type: "time"
      }
    ]
  },
  {
    sectionTitle: "Event Setup",
    itemList: [
      {
        label: "Select Event",
        name: "eventType",
        type: "select",
        values: ["ktm", "ktmv5", "infiniteKtm", "trv3"],
        valueLabels: ["KTM", "KTMv5", "Infinite Ktm", "TRv3"],
      },
      {
        label: "Event Start Time",
        name: "eventStartTime",
        type: "time"
      },
      {
        label: "Event End Time",
        name: "eventEndTime",
        type: "time"
      },
      {
        label: "Set Event",
        name: "SetEvent",
        type: "buttonEvent",
      },]
  },
]
